<template>
    <div>
        <bread-crumbs :items="items"></bread-crumbs>
        <v-divider></v-divider>
        <v-row class="m-2">
            <v-col cols="12">
                <div style="margin:5px;" class="rowTitle">
                    <b-card no-body class="mb-1">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <div style="display:inline-flex;width:11.111%">
                            رقم الطلب: {{ orderid }}
                            </div>
                            <div style="display:inline-flex;width:22.222%">
                            تاريخ الاضافة: &nbsp;&nbsp; <span style="direction:ltr">{{ crminfo.created_date }}</span>
                            </div>
                            
                        </b-card-header>
                    </b-card>
                </div>
            </v-col>
                <div style="display:inline-flex;width:10%;" class="smonly" v-if="isEditable == 0">
                    <b-button class="mybtn4" v-b-toggle.change_crmevery>تعديل</b-button>
                </div>

                <div style="display:inline-flex;width:10%;" class="smonly">
                    <b-button class="mybtn2" style="background:#abd46c !important;color:#000" v-b-toggle.add_crmtask>اضافة مهمة</b-button>
                </div>

                
                <div style="display:inline-flex;width:10%" class="smonly">
                <b-button class="mybtn2" style="background:#bd8bc0 !important;color:#000" v-b-toggle.add_crmnote>ملاحظات </b-button>
                </div>
                
                <div style="display:inline-flex;width:10%" class="smonly" v-if="isEditable == 0">
                <b-button class="mybtn2" style="background:#00c4fb !important;color:#000"  v-b-toggle.add_product >اضافة منتج </b-button>
                </div>
               <div style="display:inline-flex;width:10%;" class="smonly" v-if="isEditable == 0">
                    <b-button class="mybtn4" v-b-toggle.add_quotation>عرض سعر</b-button>
               </div>
               <div style="display:inline-flex;width:10%;" class="smonly">
                    <b-button class="mybtn2" v-b-toggle.add_crm_support>دعم فني</b-button>
               </div>
   
                <div style="display:inline-flex;width:10%" class="smonly" v-if="isProducts != 0">
                    <b-button class="mybtn1" v-if="crminfo.invid == 0 && $store.state.group_id != 3" v-b-toggle.add_crminvoice>فاتورة </b-button>
                    <b-button class="mybtn1" v-if="crminfo.invid != 0" @click="printPDF(crminfo.invid)">طباعة الفاتورة </b-button>
                </div>
     
        </v-row>
        <v-row class="ma-2">
            <v-col cols="12" md="3" sm="12">
                <v-simple-table 
                width="100%"
                id="MainTable"
                hide-default-footer
                class="table-sm"
                >
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th colspan="2">معلومات العميل</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>اسم العميل</th>
                                <td>{{ crminfo.full_name }}</td>
                            </tr>
                            <tr>
                                <th>رقم الجوال</th>
                                <td>{{ crminfo.mobile }}</td>
                            </tr>
                            <tr>
                                <th>الشركة/المؤسسة</th>
                                <td>{{ crminfo.company }}</td>
                            </tr>
                            <tr>
                                <th>الاسم التجاري</th>
                                <td>{{ crminfo.trademark }}</td>
                            </tr>
                            <tr>
                                <th>السجل التجاري</th>
                                <td>{{ crminfo.crt_number }}</td>
                            </tr>
                            <tr>
                                <th>الرقم الضريبي</th>
                                <td>{{ crminfo.vatid }}</td>
                            </tr>
                            <tr>
                                <th>العنوان</th>
                                <td>{{ crminfo.address }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col cols="12" md="3" sm="12">
                <v-simple-table 
                width="100%"
                id="MainTable"
                hide-default-footer
                class="table-sm"
                >
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th colspan="2">معلومات الطلب</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>النشاط</th>
                                <td>{{ _activity }}</td>
                            </tr>
                            <tr>
                                <th>تأمين أجهزة</th>
                                <td>{{ _need_computers }}</td>
                            </tr>
                            <tr>
                                <th>عدد النقاط</th>
                                <td>{{ crminfo.posnumebr }}</td>
                            </tr>
                            <tr>
                                <th>نوع النظام</th>
                                <td>{{ _systemtype }}</td>
                            </tr>
                            <tr>
                                <th>المندوب</th>
                                <td>{{ crminfo._agent }}</td>
                            </tr>
                            <tr>
                                <th :style="`background:`+crminfo.bgcolor">حالة الطلب</th>
                                <td :style="`background:`+crminfo.bgcolor">{{ _crmstatus }}</td>
                            </tr>
                            <tr>
                                <th>عمولة المندوب</th>
                                <td>{{ crminfo.commitions }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <v-simple-table 
                width="100%"
                id="MainTable"
                hide-default-footer
                class="table-sm"
                >
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th colspan="6">المنتجات</th>
                            </tr>
                            <tr>
                                <th class="text-center;">اسم المنتج</th>
                                <th class="text-center;">الكمية</th>
                                <!-- <th class="text-center;">السعر</th> -->
                                <th class="text-center;">الاجمالي</th>
                                <th class="text-center;">الضريبة</th>
                                <th class="text-center;">المجموع</th>
                                <th v-if="crminfo.invid == 0">الاجراء</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in crminfo.products" :key="index">
                                <td class="text-center">{{ item.name }}</td>
                                <td class="text-center">{{ item.qty }}</td>
                                <!-- <td class="text-center">{{ item.price }}</td> -->
                                <td class="text-center">{{ item.total }}</td>
                                <td class="text-center">{{ item.vat }}</td>
                                <td class="text-center">{{ item.ftotal }}</td>
                                <td v-if="crminfo.invid == 0">
                                    <b-button class="mybtn5" @click="remove(item,index)">حذف</b-button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th></th>
                                <th></th>
                                <th class="text-center">{{ o_total }}</th>
                                <th class="text-center">{{ o_vat }}</th>
                                <th class="text-center">{{ o_ftotal }}</th>
                                <th v-if="crminfo.invid == 0"></th>
                            </tr>
                        </tfoot>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row class="ma-2">
            <v-col cols="12" md="12" sm="12">

                <v-data-table
                    :headers="tasksHead"
                    :items="crminfo.tasks"
                    :items-per-page="5"
                    :page.sync="page"
                    width="100%"
                    hide-default-footer
                    @page-count="pageCount = $event"
                    fixed-header
                    class="table-sm"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{row.item.message}}</td>
                            <td>{{row.item._created_by}}</td>
                            <td>{{row.item._response}}</td>
                            <td>{{row.item.deadline}}</td>
                            <td>{{row.item.closed_date}}</td>
                            <td>{{row.item._status}}</td>
                            <td>{{row.item._closed_by}}</td>
                            <td style="width:50px;">
                                <button @click="closeTask(row.item)" class="mybtn2 btn btn-success">اغلاق</button>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="12" md="12" sm="12">

                <v-data-table
                    :headers="SupportHead"
                    :items="crminfo.support"
                    :items-per-page="5"
                    :page.sync="page"
                    width="100%"
                    hide-default-footer
                    @page-count="pageCount = $event"
                    fixed-header
                    class="table-sm"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{row.item.message}}</td>
                            <td>{{row.item._created_by}}</td>
                            <td>{{row.item._response}}</td>
                            <td>{{row.item.deadline}}</td>
                            <td>{{row.item.closed_date}}</td>
                            <td>{{row.item._status}}</td>
                            <td>{{row.item._closed_by}}</td>
                            <td style="width:50px;">
                                <button @click="closeSupport(row.item)" class="mybtn2 btn btn-success">اغلاق</button>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <v-simple-table 
                width="100%"
                id="MainTable"
                hide-default-footer
                class="table-sm"
                >
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th colspan="7">عروض السعر</th>
                            </tr>
                            <tr>
                                <th class="text-center;">رقم العرض</th>
                                <th class="text-center;">الخصم</th>
                                <th class="text-center;">الاجمالي</th>
                                <th class="text-center;">الضريبة</th>
                                <th class="text-center;">المجموع</th>
                                <th class="text-center;">بواسطة</th>
                                <th>الاجراء</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in crminfo.quotations" :key="index">
                                <td>{{ item.quot_number }}</td>
                                <td>{{ item.discount }}</td>
                                <td>{{ item.total }}</td>
                                <td>{{ item.vat }}</td>
                                <td>{{ item.ftotal }}</td>
                                <td>{{ item._created_by }}</td>
                                <td>
                                    <button type="button" class="btn btn-success" @click="pQout(item.id)">طباعة</button>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                 </v-simple-table>

                <v-data-table
                    dense
                    :headers="noteheaders"
                    :items="crminfo.notes"
                    class="elevation-1 m-1"
                    :items-per-page="5"
                    :page.sync="notepage"
                    width="100%"
                    id="MainTable"
                    hide-default-footer
                    @page-count="pageCount = $event"
                    fixed-header
                ></v-data-table>

            </v-col>
            <v-col cols="12" md="6" sm="12">
  
                <v-simple-table 
                width="100%"
                id="MainTable"
                hide-default-footer
                class="table-sm m-1"
                >
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>الشروط والأحكام</th>
                                <th class="text-center" style="width:50px;">الاجراء</th>
                                <th class="text-center" style="width:50px;">تضمين</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v>
                                <td>شروط واحكام السداد</td>
                                <td class="text-center">
                                    <b-button type="button" class="btn-sm" v-b-toggle.payment_terms variant="success">تعديل</b-button>
                                </td>
                                <td class="text-center">
                                    <v-checkbox
                                    v-model="terms.payments"
                                    disabled
                                    ></v-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>شروط واحكام التركيب والتوصيل</td>
                                <td class="text-center">
                                    <b-button type="button" class="btn-sm" variant="success" v-b-toggle.delivery_terms>تعديل</b-button>
                                </td>
                                <td class="text-center">
                                    <v-checkbox
                                    v-model="terms.delivery"
                                    disabled
                                    ></v-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>شروط واحكام الدعم الفني</td>
                                <td class="text-center">
                                    <b-button type="button" class="btn-sm" v-b-toggle.support_terms variant="success">تعديل</b-button>
                                </td>
                                <td class="text-center">
                                    <v-checkbox
                                    v-model="terms.support"
                                    disabled
                                    ></v-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>متطلبات يجب توفيرها من قبل العميل</td>
                                <td class="text-center">
                                    <b-button type="button" class="btn-sm" v-b-toggle.requirements_terms variant="success">تعديل</b-button>
                                </td>
                                <td class="text-center">
                                    <v-checkbox
                                    v-model="terms.requirements"
                                    disabled
                                    ></v-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>نطاق العمل</td>
                                <td class="text-center">
                                    <b-button type="button" class="btn-sm" v-b-toggle.scope_terms variant="success">تعديل</b-button>
                                </td>
                                <td class="text-center">
                                    <v-checkbox
                                    v-model="terms.inscope"
                                    disabled
                                    ></v-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>خارج نطاق العمل</td>
                                <td class="text-center">
                                    <b-button type="button" class="btn-sm" v-b-toggle.outscope_terms variant="success">تعديل</b-button>
                                </td>
                                <td class="text-center">
                                    <v-checkbox
                                    v-model="terms.outofscope"
                                    disabled
                                    ></v-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td>طرق السداد</td>
                                <td class="text-center">
                                    <b-button type="button" class="btn-sm" v-b-toggle.pay_gates variant="success">تعديل</b-button>
                                </td>
                                <td class="text-center">
                                    <v-checkbox
                                    v-model="terms.paygates"
                                    disabled
                                    ></v-checkbox>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                
            </v-col>
        </v-row>
        
        <addCRMInvoice />
        <addQuotation />
        <addProduct />
        <addCRMNotes />
        <addTask />
        <scopeTerms />
        <paymentTerms />
        <deliveryTerms />
        <supportTerms />
        <requirementTerms />
        <outScope />
        <payGetesTerms />
        <addCRMSupport />
        <change-status />
    </div>
</template>

<script>

import addProduct from '@/components/addProduct.vue'
import addCRMNotes from '@/components/addCRMNotes.vue'
import addTask from '@/components/addTask.vue'
import addCRMSupport from '@/components/addCRMSupport.vue'
import paymentTerms from '@/components/PaymentTerms.vue'
import deliveryTerms from '@/components/deliveryTerms.vue'
import supportTerms from '@/components/supportTerms.vue'
import requirementTerms from '@/components/requirementTerms.vue'
import scopeTerms from '@/components/scopeTerms.vue'
import outScope from '@/components/outScope.vue'
import payGetesTerms from '@/components/payGetesTerms.vue'
import addQuotation from '@/components/addQuotation.vue'
import axios from 'axios'
import addCRMInvoice from '@/components/addCRMInvoice.vue'
import BreadCrumbs from '@/components/breadCrumbs.vue'
import ChangeStatus from '@/components/changeStatus.vue'
export default{
    name: 'ViewOrder',
    components: {addProduct,
                addCRMNotes,addTask,addCRMSupport,addCRMInvoice,BreadCrumbs,
                paymentTerms,deliveryTerms,supportTerms,requirementTerms,
                scopeTerms,outScope,payGetesTerms,addQuotation,
                ChangeStatus
            }, 
    data() {
        return {
            quotations:[], 
            page: 1,
            pageCount: 0,
            crminfo: [],
            tasksHead:[
                {
                    text: 'المهمة',
                    align: '',
                    filterable: false,
                    sortable:false,
                    value: 'message',
                },
                {
                    text: 'بواسطة',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'created_by',
                },
                {
                    text: 'المسؤول',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'response',
                },
                {
                    text: 'الموعد النهائي',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'deadline',
                },
                {
                    text: 'تاريخ الاغلاق',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'close_date',
                },
                {
                    text: 'الحالة',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: '_status',
                },
                {
                    text: 'اغلاق بواسطة',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: '_closed_by',
                },
                {
                    text: 'الاجراء',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: '',
                },
            ],
            SupportHead:[
                {
                    text: 'وصف طلب الصيانة',
                    align: '',
                    filterable: false,
                    sortable:false,
                    value: 'message',
                },
                {
                    text: 'بواسطة',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'created_by',
                },
                {
                    text: 'المسؤول',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'response',
                },
                {
                    text: 'الموعد النهائي',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'deadline',
                },
                {
                    text: 'تاريخ الاغلاق',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'close_date',
                },
                {
                    text: 'الحالة',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: '_status',
                },
                {
                    text: 'اغلاق بواسطة',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: '_closed_by',
                },
                {
                    text: 'الاجراء',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: '',
                },
            ],
            tasksRows:[],
            whtsrows:[],
            terms: {
                payments: true,
                support: true,
                delivery: true,
                requirements: true,
                inscope: true,
                outofscope: true,
                paygates: true,
            },
            paymentsTHeader:[
                {
                    text: 'الدفعة',
                    align: '',
                    filterable: false,
                    sortable:false,
                    value: 'paytext',
                },
                {
                    text: 'النسبة',
                    align: '',
                    filterable: false,
                    sortable:false,
                    value: 'percent',
                },
                {
                    text: 'مبلغ الدفعة',
                    align: '',
                    filterable: false,
                    sortable:false,
                    value: 'paty_total',
                },
                {
                    text: 'الحالة',
                    align: '',
                    filterable: false,
                    sortable:false,
                    value: 'status',
                },
                {
                    text: 'الاجراء',
                    align: '',
                    filterable: false,
                    sortable:false,
                },
            ],
            payTrows:[],
            noteheaders: [
                {
                    text: 'الملاحظة',
                    align: '',
                    filterable: false,
                    sortable:false,
                    value: 'message',
                    style: "width:50%"
                },
                // {
                //     text: 'بواسطة',
                //     align: 'center',
                //     filterable: false,
                //     sortable:false,
                //     value: 'createdby'
                // },
                {
                    text: 'تاريخ',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'created_date'
                },
                
            ],
            noterows: [
            ],
            smsheaders: [
                {
                    text: this.$store.state.hometitle,
                    align: '',
                    filterable: false,
                    sortable:false,
                    value: 'message',
                    style: "width:50%"
                },
                // {
                //     text: 'المرسل',
                //     align: 'center',
                //     filterable: false,
                //     sortable:false,
                //     value: 'createdby'
                // },
                {
                    text: 'تاريخ',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'created_date'
                },
                
            ],
            smsrows: [
            ],
            whtsheaders: [
                {
                    text: 'رسالة الواتس اب',
                    align: '',
                    filterable: false,
                    sortable:false,
                    value: 'message',
                    style: "width:50%"
                },
                {
                    text: 'النوع',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'msgtype'
                },
                {
                    text: 'تاريخ',
                    align: 'center',
                    filterable: false,
                    sortable:false,
                    value: 'created_date'
                },
                
            ],
            orderid: 0,
            paytotal: 0,
            o_total: 0,
            o_vat: 0,
            o_ftotal: 0,
            notepage: 0,
            custorder: {},
            products:[],
            
            alertColor: 'success',
            paymentsTable: [],
            fullrows: [],
        }
    },
    methods: {
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?invid='+id;
        },
        pQout(id){
            window.open('../api/print.php?quotid='+id,'_blank');
        },
        remove(item,index){
            this.crminfo.products.splice(index, 1); 
            const post = new FormData();
            post.append("type" , 'rfCrmProduct');
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[prid]",item.id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            )
            .then((response) => {
                this.getOrder();
                this.calckTot();
            })
            this.calckTot();
        },
        getOrder() {
            const post = new FormData();
            post.append('type','getCRMByID');
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[crmid]",this.$route.params.id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // // console.log("Loool",res);
                this.crminfo = res.results.data;
            }).then(() => {
                this.calckTot()
            })
        },
        calckTot(){
            const ordp = this.crminfo.products;
            let tot = 0;
            let vat = 0;
            let ftot = 0;
            if(ordp.length > 0){
                for(let i=0;i<ordp.length;i++){
                    tot = +ordp[i].total + +tot;
                    vat = +ordp[i].vat + +vat;
                    ftot = +ordp[i].ftotal + +ftot;
                }
            }
            this.o_total = this.$RoundNum(tot);
            this.o_vat = this.$RoundNum(vat);
            this.o_ftotal = this.$RoundNum(ftot);
        },
        closeTask(item){
            
            if(item.status == 5){
                return false;
            }
            const post = new FormData();
            post.append("type","CloseTaskById")
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[taskid]",item.id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                this.getOrder();
            })
        },
        intLicense(){
            const post = new FormData();
            post.append("type","intLicense")
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[crmid]",this.$route.params.id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                
                this.getOrder();
            })
        },
        getAsettings(){
            const post = new FormData();
            post.append("type","getCRMSettigns");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]",'1');
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // // console.log("----",res.results.data);
                this.fullrows = res.results.data;
            });
        },
    },
    created() {
        this.orderid = this.$route.params.id;
        this.getOrder();
        this.getAsettings();
    },
    computed: {
        items:function() {
            
            return {
                text: 'استعراض عميل',
                disabled: true,
                href: '/',
            }
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        activities: function() {
            let x = [];
            if(this.fullrows.length == 0) return [];
            for(let i = 0;i < this.fullrows[1].length;i++){
                let xx = {
                    text: this.lang.langname == "ar" ? this.fullrows[1][i].namear : this.fullrows[1].nameen,
                    value: this.fullrows[1][i].id,
                }
                x.push(xx);
            }
            return x;
        },
        yesNo: function() {
            let x = [];
            if(this.fullrows.length == 0) return [];
            for(let i = 0;i < this.fullrows[0].length;i++){
                let xx = {
                    text: this.lang.langname == "ar" ? this.fullrows[0][i].namear : this.fullrows[0].nameen,
                    value: this.fullrows[0][i].id,
                }
                x.push(xx);
            }
            return x;
        },
        systemtypes: function() {
            let x = [];
            if(this.fullrows.length == 0) return [];
            for(let i = 0;i < this.fullrows[2].length;i++){
                let xx = {
                    text: this.lang.langname == "ar" ? this.fullrows[2][i].namear : this.fullrows[2].nameen,
                    value: this.fullrows[2][i].id,
                }
                x.push(xx);
            }
            return x;
        },
        orderStatus: function() {
            let x = [];
            if(this.fullrows.length == 0) return [];
            for(let i = 0;i < this.fullrows[3].length;i++){
                let xx = {
                    text: this.lang.langname == "ar" ? this.fullrows[3][i].namear : this.fullrows[3].nameen,
                    value: this.fullrows[3][i].id,
                }
                x.push(xx);
            }
            return x;
        },
        _activity: function() {
            let t = '';
            for(let i=0;i<this.activities.length;i++){
                if(this.activities[i].value == this.crminfo.activity){
                    t = this.activities[i].text;
                }
            }
            return t;
        },
        _need_computers: function() {
            let t = '';
            for(let i=0;i<this.yesNo.length;i++){
                if(this.yesNo[i].value == this.crminfo.need_computers){
                    t = this.yesNo[i].text;
                }
            }
            return t;
        },
        _systemtype: function() {
            let t = '';
            for(let i=0;i<this.systemtypes.length;i++){
                if(this.systemtypes[i].value == this.crminfo.systemtype){
                    t = this.systemtypes[i].text;
                }
            }
            return t;
        },
        _crmstatus: function() {
            let t = '';
            for(let i=0;i<this.orderStatus.length;i++){
                if(this.orderStatus[i].value == this.crminfo.status){
                    t = this.orderStatus[i].text;
                }
            }
            return t;
        },
        isProducts: function() {
            let t = 0;
            
            if(typeof this.crminfo.products !== 'undefined' && this.crminfo.products.length > 0)
                t = 1
            
            return t;
        },
        isEditable: function(){
            let t = 1;
            if(this.crminfo.invid == 0){
                t = 0;
            }
            return t;
        }
    },
}
</script>
<style>
.font-size-14{
  font-size:14px;
}
.mybtn{
  width:100%;
  font-size:.8rem;
  border:0px solid #fff;
}
.mybtn2{
  width:100%;
  font-size:.8rem;
  background:rgb(230, 15, 62) !important;
  border:0px solid #fff;
}
.mybtn4{
  width:100%;
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
.mybtn3{
  width:100%;
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
.mybtn1{
  width:100%;
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
.v-application .elevation-1{
 box-shadow: none !important;
}
.mybtn5{
  width:100%;
  font-size:.8rem;
  background:#cc0902 !important;
  border:0px solid #fff;
}
@media only screen and (max-width: 600px) {
    .smonly{
        width:50% !important;
        margin-top:3px;
    }
}
</style>